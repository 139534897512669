<template>
  <AbstractFilter
    :on-filter="filterRedirects"
    :on-filter-reset="resetFilter"
    :button-required-permissions="requiredPermissions"
    create-new-route-name="redirect_new"
    filter-panel-class="filter-panel-redirect-vlm"
  >
    <SiteSelect
      v-if="vlm"
      v-model="filter.siteId"
      :options="sites"
      :label="$t('redirectVlm.filter.server')"
      id="filter_site"
    />
    <Select
      v-else
      v-model="filter.siteId"
      :options="sites"
      :label="$t('redirectVlm.filter.server')"
      id="filter_site"
    />
    <div>
      <Input
        v-model="filter.originalUrl"
        @blur="$v.filter.originalUrl.$touch()"
        :error="$v.filter.originalUrl.$error"
        id="filter_originalUrl"
        :label="$t('redirectVlm.filter.originalUrl')"
        disable-form-group
      />
      <div v-if="$v.filter.originalUrl.$error" class="input-validation__info-text error-message">
        {{ $t('redirectVlm.errors.invalidOriginalUrl') }}
      </div>
    </div>
    <Input
      v-model="filter.alias"
      id="filter_id"
      :label="$t('redirectVlm.filter.alias')"
    />
    <Datepicker
      v-model="filter.validFrom"
      id="filter_validFrom"
      :label="$t('redirectVlm.filter.validFrom')"
      type="date"
    />
    <Datepicker
      v-model="filter.validTo"
      id="filter_validTo"
      :label="$t('redirectVlm.filter.validTo')"
      type="date"
    />
  </AbstractFilter>
</template>
<script>
import { isURL } from '@/filters'
import Input from '@/components/form/inputs/Input'
import AbstractFilter from '@/components/filter/AbstractFilter'
import Select from '@/components/form/select/Select'
import Datepicker from '@/components/form/Datepicker'
import PermissionService from '@/services/PermissionService'
import { mapGetters } from 'vuex'
import SiteSelect from '@/components/form/select/SiteSelect.vue'

export default {
  name: 'RedirectFilterVlm',
  data () {
    return {
      filter: {},
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_REDIRECT_PERMISSIONS
    }
  },
  components: {
    SiteSelect,
    AbstractFilter,
    Input,
    Select,
    Datepicker
  },
  validations: {
    filter: {
      originalUrl: {
        isUrl: (value) => !value || isURL(value)
      }
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    sites () {
      return this.$store.getters['site/allEnabledSorted']()
    }
  },
  methods: {
    filterRedirects () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$store.commit('redirectVlm/setPage', 1)
        this.$store.commit('redirectVlm/setFilter', this.filter)
        this.$store.dispatch('redirectVlm/fetch')
      }
    },
    resetFilter () {
      this.$store.commit('redirectVlm/resetFilter')
      this.setFilter()
      this.$store.dispatch('redirectVlm/fetch')
    },
    setFilter () {
      this.filter = this._.cloneDeep(this.$store.getters['redirectVlm/filter'])
    }
  },
  beforeMount () {
    this.setFilter()
  }
}
</script>

<style lang="scss">
.filter-panel-redirect-vlm {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  align-items: baseline;
}
</style>
<style lang="scss" scoped>
.error-message {
  @include font(400 13px "Roboto");
  padding-top: 0.2rem;
  color: #FF3455;
  text-align: right;
}
</style>
